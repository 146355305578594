















































































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import moment from 'moment';
import axios from 'axios';
const excel = require('../libs/excel');
const _ = require('lodash');

export default Vue.extend({
  components: {
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    return {
      display: false,
      columns: Array<any>(),
      statRows: [],
      rows: [],
      instruments: Array<any>(),
      form: {
        instrument: {},
        range,
      },
      currentPage: 1,
    };
  },
  mounted() {
    this.getInstruments();
  },
  methods: {
    getInstruments() {
      axios.get('/Instruments').then(res => {
        const ret = res.data;
        this.instruments = ret;
        if (this.instruments.length !== 0) {
          this.form.instrument = this.instruments[0]._id;
        }
      });
    },
    async query() {
      this.display = true;
      const url = `/InstrumentStatusReport/${this.form.instrument}/${this.form.range[0]}/${this.form.range[1]}`;
      const res = await axios.get(url);
      this.handleReport(res.data);
    },
    handleReport(report: any) {
      this.columns.splice(0, this.columns.length);

      this.columns.push({
        key: 'time',
        label: '時間',
        sortable: true,
      });

      for (let i = 0; i < report.columnNames.length; i++) {
        this.columns.push({
          key: `cellData[${i}].v`,
          label: `${report.columnNames[i]}`,
          sortable: true,
          stickyColumn: true,
        });
      }
      for (const row of report.rows) {
        row.time = moment(row.date).format('lll');
      }
      this.rows = report.rows;
      this.statRows = report.statRows;
    },
    exportExcel() {
      const title = this.columns.map(e => e.label);
      const key = this.columns.map(e => e.key);
      for (let entry of this.rows) {
        let e = entry as any;
        for (let k of key) {
          e[k] = _.get(entry, k);
        }
      }
      const params = {
        title,
        key,
        data: this.rows,
        autoWidth: true,
        filename: `${this.form.instrument}儀器參數`,
      };
      excel.export_array_to_excel(params);
    },
  },
});
